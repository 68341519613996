import axios from 'axios';

import { MomentState } from '../utils/constants';
import { updateMomentState } from '../services/momentService';

class UploadEvent extends EventTarget {

  moment = null;
  progress = 0;
  completed = false;
  failed = false;
  url = null;
  data = null;

  constructor(moment) {
    super();
    this.moment = moment;
  }

  async upload(url, data) {
    this.url = url;
    this.data = data;
    return axios.put(url, data, {
      onUploadProgress: async (evt) => {
        this.progress = evt.loaded / evt.total;
        this.dispatchEvent(new CustomEvent('progress', { 
          detail: { 
            progress: this.progress 
          } 
        }));
        const total = Math.round((evt.loaded * 100) / evt.total);
        if (total >= 100) {
          this.completed = true;
          const res = await updateMomentState(
            this.moment,
            MomentState.Transcoding
          );
          this.dispatchEvent(new CustomEvent('completed', {
            detail: res.data
          }));
        }
      }
    }).catch(err => {
      this.failed = true;
      this.dispatchEvent(new CustomEvent('failed', {
        detail: {
          error: err
        }
      }));
    });
  }
}

const uploadEvents = {};

export const allUploadEvents = () => {
  return Object.entries(uploadEvents).map(ue => ue[1]);
};

export const getUploadEvent = (id) => {
  return uploadEvents[id] || null;
};

export const upload = ({ moment, data, url }) => {
  const id = moment.mfe_key;

  uploadEvents[id] = new UploadEvent(moment);
  uploadEvents[id].upload(url, data);

  return uploadEvents[id];
};
