import * as React from 'react';
import { Subheading, useTheme } from 'react-native-paper';

function MfeSubheading(props) {
  const theme = useTheme();
  return (
    <Subheading style={{
      color: theme.colors.primary,
      textTransform: 'uppercase',
      ...props.style
    }}
    >
      {props.children}
    </Subheading>
  );
}
export default MfeSubheading;
