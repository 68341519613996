import { get as apiGet, post as apiPost, put as apiPut } from './apiService';

export const createMoment = async (params) => {
  let res = null;

  try {
    res = await apiPost('/moments', params);
  } catch (e) {
    console.log(e);
  }

  return res;
};

export const refreshMoment = async (moment) => {
  let res = null;

  try {
    res = await apiPut(`/moments/${moment.mfe_key}/refresh`, moment);
  } catch (e) {
    console.log(e);
  }

  return res;
};

export const updateMoment = async (moment) => {
  let res = null;

  try {
    res = await apiPut(`/moments/${moment.mfe_key}`, moment);
  } catch (e) {
    console.log(e);
  }

  return res;
};

export const updateMomentState = async (moment, newState, details) => {
  let res = null;

  try {
    res = await apiPut(`/moments/${moment.mfe_key}/state`, {
      new_state: newState,
      details
    });
  } catch (e) {
    console.log(e);
  }

  return res;
};

export const stateMoments = async (state) => {
  let res = null;

  try {
    res = await apiGet(`/moments/${state}`);
  } catch (e) {
    console.log(e);
  }

  return res;
};

export const userMoments = async () => {
  let res = null;

  try {
    res = await apiGet('/moments/user');
  } catch (e) {
    console.log(e);
  }

  return res;
};

export const activeMoments = async () => {
  let res = null;

  try {
    res = await apiGet('/moments/active');
  } catch (e) {
    console.log(e);
  }

  return res;
};