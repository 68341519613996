import { useDropzone } from 'react-dropzone';

import React, { useEffect, useMemo, useState } from 'react';

import { ActivityIndicator, ScrollView, View } from 'react-native';

import {
  ProgressBar,
  Title,
  useTheme
} from 'react-native-paper';

import { createMoment, refreshMoment, userMoments } from '../services/momentService';
import { getUploadEvent, upload } from '../services/uploadService.web';

import { MomentState } from '../utils/constants';

import MfeButton from './MfeButton';
import MfeSpacer from './MfeSpacer';

import MomentListView, { ListMode } from './MomentListView/MomentListView';

function MfeUploadItem(props) {

  const theme = useTheme();

  const [moment, setMoment] = useState(props.moment);
  const [uploadEvent, setUploadEvent] = useState(null);
  const [progress, setProgress] = useState(0);
  const [refreshed, setRefreshed] = useState(false);
  const [files, setFiles] = useState([]);

  const onUploadEventProgress = (async evt => {
    setProgress(evt.detail.progress);
  }).bind(this);

  const onUploadEventCompleted = (async evt => {
    setMoment(evt.detail.moment);
  }).bind(this);

  const onUploadEventFailed = (async evt => {
    console.log(evt.detail.error);
  }).bind(this);

  // get existing upload event, listen for progress and completed
  useEffect(() => {
    const existingUploadEvent = getUploadEvent(moment.mfe_key);
    if (existingUploadEvent) {
      setUploadEvent(existingUploadEvent);
      existingUploadEvent.addEventListener('progress', onUploadEventProgress);
      existingUploadEvent.addEventListener('completed', onUploadEventCompleted);
      existingUploadEvent.addEventListener('failed', onUploadEventFailed);
    }
    return () => {
      if (existingUploadEvent) {
        existingUploadEvent.removeEventListener('progress', onUploadEventProgress);
        existingUploadEvent.removeEventListener('completed', onUploadEventCompleted);
        existingUploadEvent.removeEventListener('failed', onUploadEventFailed);
      }
    }
  }, []);

  // get refreshed upload event, listen for progress and completed
  useEffect(() => {
    if (uploadEvent) {
      uploadEvent.addEventListener('progress', onUploadEventProgress);
      uploadEvent.addEventListener('completed', onUploadEventCompleted);
      uploadEvent.addEventListener('failed', onUploadEventFailed);
    }
    return () => {
      if (uploadEvent) {
        uploadEvent.removeEventListener('progress', onUploadEventProgress);
        uploadEvent.removeEventListener('completed', onUploadEventCompleted);
        uploadEvent.removeEventListener('failed', onUploadEventFailed);
      }
    }
  }, [refreshed]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'video/*',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: setFiles
  });

  const file = files[0];
  if (file && !refreshed) {
    if (file.name !== moment.filename) {
      alert(`Filename must match: ${moment.filename}`);
      setFiles([]);
    } else {
      (async (f) => {
        const res = await refreshMoment(moment);
        const data = res.data;
        setUploadEvent(upload({
          moment: moment,
          data: f,
          url: data.presigned_url
        }));
        setRefreshed(true);
      })(file);
    }
  }

  return (
    <div>
      <Title>{moment.filename}</Title>
      <br />
      <Title>{moment.state}</Title>
      {(uploadEvent && moment.state === MomentState.Uploading) && (
        <ProgressBar progress={progress} color={theme.colors.accent} />
      )}
      {(!uploadEvent && moment.state === MomentState.Uploading) && (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <MfeButton onPress={open}>Resume</MfeButton>
        </div>
      )}
    </div>
  );
}

function MfeUploader(props) {

  const [moments, setMoments] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    (async function getMoments () {
      const res = await userMoments();
      let moms = res.data || [];
      moms = moms.map(m => ({...m, stream: (m.stream && JSON.parse(m.stream) || null) }))
      moms.sort((a, b) => {
        return b.created - a.created;
      });
      setMoments(moms);
    })();
  }, []);

  const theme = useTheme();

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: theme.fonts.regular.fontFamily,
    padding: '20px',
    margin: '20px',
    borderWidth: 1,
    borderRadius: 2,
    borderColor: theme.colors.placeholder,
    borderStyle: 'dashed',
    backgroundColor: theme.colors.inputBackground,
    color: theme.colors.placeholder,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'video/*',
    multiple: false,
    onDropAccepted: setFiles
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    ...theme
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
    theme
  ]);

  const file = files[0];
  if (file) {
    setFiles([]);
    (async (f) => {
      const res = await createMoment({
        filesize: f.size,
        filename: f.name
      });
      const data = res.data;
      upload({
        moment: data.moment,
        data: f,
        url: data.presigned_url
      });
      setMoments([data.moment, ...moments]);
    })(file);
  }

  return (
    <>
      <MfeSpacer size={20} />
      <Title>Uploader</Title>
      <MfeSpacer />
      <View style={{ width: '100%' }}>
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <p>Drop video here, or click to choose a video</p>
        </div>
      </View>
      <ScrollView style={{ flex: 1 }}>
        <View style={{ flex: 1, width: '100%', alignItems: 'center' }}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            {moments === null && (<ActivityIndicator color={theme.colors.primary} size="small" />)}
            {moments?.length > 0 && (<MomentListView moments={moments} mode={ListMode.Uploader} onResume={(m) => console.log(m) }/>)}
          </View>
        </View>
      </ScrollView>
    </>
  );
}
export default MfeUploader;
