import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Platform, Image, TouchableHighlight, ScrollView, View } from 'react-native';
import {
  Button,
  TextInput,
  Title,
  useTheme
} from 'react-native-paper';

import { stateMoments, updateMomentState } from '../services/momentService';
import { MomentState } from '../utils/constants';

import MfeSpacer from './MfeSpacer';

import Video from './Video/Video';

import MomentListView, { ListMode } from './MomentListView/MomentListView';

function MomentPanel({ moment, close, onApproval, onRejection }) {
  const [details, setDetails] = useState('');
  return (
    <View>
      <MfeSpacer />
      <Video moment={moment} />
      <Title>{moment.mfe_key}</Title>
      <MfeSpacer />
      <TextInput
        placeholder="Add information about your decision here..."
        value={details}
        onChangeText={details => setDetails(details)}
        numberOfLines={2}
        multiline={true}
        mode="outlined"
        dense={true}
      />
      <MfeSpacer />
      <Button color={'green'} mode="contained" onPress={() => onApproval(moment, details)}>Approve</Button>
      <MfeSpacer />
      <Button color={'red'} mode="contained" onPress={() => onRejection(moment, details)}>Reject</Button>
      <MfeSpacer />
      <Button onPress={close}>Close</Button>
      <MfeSpacer />
    </View>
  );
}

function MfeLaboratory(props) {
  const [moments, setMoments] = useState(null);

  const theme = useTheme();

  const fetchApprovalMoments = async () => {
    const res = await stateMoments(MomentState.Approval);
    let moments = res.data || [];
    moments = moments.map(m => ({...m, stream: JSON.parse(m.stream) }));
    moments.sort((a, b) => {
      return b.created - a.created;
    });
    setMoments(moments);
  };

  useEffect(() => {
    fetchApprovalMoments();
  }, []);

  const onApproval = async (moment, details) => {
    await updateMomentState(moment, MomentState.Active, details);
    await fetchApprovalMoments();
  };

  const onRejection = async (moment, details) => {
    await updateMomentState(moment, MomentState.Rejected, details);
    await fetchApprovalMoments();
  };

  return (
    <ScrollView style={{ flex: 1 }}>
    <View style={{ flex: 1, alignItems: 'center' }}>
      <MfeSpacer size={20} />
      <Title>Laboratory</Title>
      <MfeSpacer />
      <View style={{ flex: 1, justifyContent: 'center' }}>
        {moments === null && (<ActivityIndicator color={theme.colors.primary} size="small" />)}
        {moments?.length > 0 && (<MomentListView moments={moments} mode={ListMode.Laboratory} onApproval={onApproval} onRejection={onRejection} />)}
      </View>
      <MfeSpacer />
    </View>
    </ScrollView>
  )
}
export default MfeLaboratory;