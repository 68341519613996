import axios from 'axios';

export const get = (url, headers) => {
  return axios.get(url, { headers: headers });
};

export const post = async (url, body, headers) => {
  return axios.post(url, body, { headers: headers });
};

export const put = (url, body, headers) => {
  return axios.put(url, body, { headers: headers });
};

export const del = async (url, headers) => {
  return axios.delete(url, { headers: headers });
};
