import * as React from 'react';
import { View } from 'react-native';

function MfeAuthPanel(props) {
  const maxWidth = 350;
  const minWidth = 350;
  return (
    <View style={{
      flex: 1,
      justifyContent: 'center',
      padding: 24,
      maxWidth,
      minWidth,
      alignSelf: 'center'
    }}
    >
      {props.children}
    </View>
  );
}
export default MfeAuthPanel;
