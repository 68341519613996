import axios from 'axios';

const ApiEndpoint = 'https://api.mfe.sh';
// const ApiEndpoint = 'http://localhost:3000';

import {
  get as httpGet,
  put as httpPut,
  post as httpPost,
  del as httpDel
} from './httpService';

import { token } from './authService';

const headers = async () => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${await token()}`
  };
};

const apiUrl = (path) => {
  return new URL(path, ApiEndpoint).href;
}

const apiRes = (res) => {
  return { status: res?.status, data: res?.data };
}

export const get = async (path) => {
  let res = null;

  try {
    res = await httpGet(
      apiUrl(path),
      await headers()
    );
  } catch (e) {
    console.log(e);
  }

  return apiRes(res);
};

export const post = async (path, body) => {
  let res = null;

  try {
    res = await httpPost(
      apiUrl(path),
      body,
      await headers()
    );
  } catch (e) {
    console.log(e);
  }

  return apiRes(res);
};

export const put = async (path, body) => {
  let res = null;

  try {
    res = await httpPut(
      apiUrl(path),
      body,
      await headers()
    );
  } catch (e) {
    console.log(e);
  }

  return apiRes(res);
};

export const del = async (path) => {
  let res = null;

  try {
    res = await httpDel(
      apiUrl(path),
      await headers()
    );
  } catch (e) {
    console.log(e);
  }

  return apiRes(res);
};