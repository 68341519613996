import * as React from 'react';
import { View } from 'react-native';

const defaultSize = 10;

function MfeSpacer(props) {
  return (
    <View style={{ margin: props.size || defaultSize }} />
  );
}
export default MfeSpacer;
