export const MomentState = {
  Uploading: 'moment_state.uploading',
  Uploaded: 'moment_state.uploaded',
  Transcoding: 'moment_state.transcoding',
  Transcoded: 'moment_state.transcoded',
  Approval: 'moment_state.approval',
  Active: 'moment_state.active',
  Rejected: 'moment_state.rejected',
  Failed: 'moment_state.failed',
};

export const UserRole = {
  Guest: 'user_role.guest',
  Candidate: 'user_role.candidate',
  Member: 'user_role.member',
  SuperMember: 'user_role.supermember',
  Moderator: 'user_role.moderator',
  Curator: 'user_role.curator',
};