import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, StatusBar, FlatList, useColorScheme, TouchableOpacity, View } from 'react-native';

import { NavigationContainer, useTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { useFonts } from 'expo-font';

import changeNavigationBarColor from 'react-native-navigation-bar-color';

import {
  Button,
  configureFonts,
  DarkTheme,
  DefaultTheme,
  Provider as PaperProvider,
  TextInput
} from 'react-native-paper';

import MfeAuthPanel from './components/MfeAuthPanel';
import MfeButton from './components/MfeButton';
import MfeSubheading from './components/MfeSubheading';
import MfeSpacer from './components/MfeSpacer';
import MfeShell from './components/MfeShell';
import MfeUploader from './components/MfeUploader';
import MfeLaboratory from './components/MfeLaboratory';

import MomentListView from './components/MomentListView/MomentListView';

import ArchiveContext from './contexts/archiveContext';

import {
  code as authServiceCode,
  login as authServiceLogin,
  logout as authServiceLogout,
  profile as authServiceProfile,
  token as authServiceToken
} from './services/authService';

import { activeMoments } from './services/momentService';

import AppLoading from 'expo-app-loading';
import { UserRole } from './utils/constants';

const AuthContext = React.createContext();

const Stack = createNativeStackNavigator();

const AuthMode = {
  Code: 'AuthMode.Code',
  CodeSent: 'AuthMode.CodeSent',
  Login: 'AuthMode.Login'
};

function AuthScreen({ navigation }) {

  const theme = useTheme();

  const [mode, setMode] = React.useState(AuthMode.Login);

  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');

  // auth
    const authContext = useContext(AuthContext);
    const doLogin = async () => {
      if (!email || !code) return;

      const token = await authServiceLogin(email, code);
      authContext.login(token);
    };

    const doRequestCode = async () => {
      if (!email) return;
      await authServiceCode(email);
      showCodeSent();
    };

    const showCode = async () => {
      setMode(AuthMode.Code);
    };

    const showCodeSent = async () => {
      setMode(AuthMode.CodeSent);
    };

    const showLogin = async () => {
      setMode(AuthMode.Login);
    };
  // ~auth

  const codeText1 = `By requesting an access code you affirm the following statements:`;
  const codeText2 = `1) I am not acting on the behalf of any government agency, whether state, federal, or international.`;
  const codeText3 = `2) I am not acting on the behalf of any corporate entity, or any organization whatsoever.`;
  const codeText4 = `3) I consent to allow media uploaded to the archive to be used by MFE in perpetuity and without restriction of any kind.`;

  const subheadingStyle = { marginBottom: 15 };
  const textInputStyle = { backgroundColor: theme.colors.inputBackground };

  const codeSentText = `Thank you. Once your email has been vetted and approved, you will be issued an access code. Use that code to log in to the Archive.`

  return (
    <MfeAuthPanel>
      {mode === AuthMode.Login && (
        <>
          <TextInput
            placeholder="Email Address"
            value={email}
            onChangeText={email => setEmail(email)}
            style={textInputStyle}
            mode="outlined"
            dense={true}
          />
          <TextInput
            placeholder="Access Code"
            value={code}
            onChangeText={code => setCode(code)}
            style={textInputStyle}
            mode="outlined"
            dense={true}
          />
          <MfeSpacer />
          <MfeButton onPress={doLogin}>Login [Archive]</MfeButton>
          <MfeSpacer size={5} />
          <Button onPress={showCode} labelStyle={{ textDecorationLine: 'underline' }}>Code</Button>
        </>
      )}
      {mode === AuthMode.Code && (
        <>
          <TextInput
            placeholder="Email Address"
            value={email}
            onChangeText={email => setEmail(email)}
            style={textInputStyle}
            mode="outlined"
            dense={true}
          />
          <MfeSpacer />
          <MfeButton onPress={doRequestCode}>Request [Access Code]</MfeButton>
          <MfeSpacer size={5} />
          <Button onPress={showLogin} labelStyle={{ textDecorationLine: 'underline' }}>Login</Button>
        </>
      )}
      {mode === AuthMode.CodeSent && (
        <>
          <MfeSubheading>{codeSentText}</MfeSubheading>
          <MfeSpacer />
          <MfeButton onPress={showLogin}>Return [Login]</MfeButton>
        </>
      )}
    </MfeAuthPanel>
  );
}

function UploadScreen ({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <MfeUploader />
      <MfeSpacer />
      <MfeButton onPress={() => navigation.navigate('Archive')}>Close</MfeButton>
      <MfeSpacer />
    </View>
  );
}

function LaboratoryScreen ({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <MfeLaboratory />
      <MfeSpacer />
      <MfeButton onPress={() => navigation.navigate('Archive')}>Close</MfeButton>
      <MfeSpacer />
    </View>
  );
}

function ArchiveScreen ({ navigation }) {

    const theme = useTheme();

    const [role, setRole] = useState(null);
    const [moments, setMoments] = useState(null);

    useEffect(() => {
      (async () => {
        const profile = await authServiceProfile();
        if (profile) setRole(profile.role);

        const active = await activeMoments();
        let momList = active.data || [];
        momList = momList.map(m => ({...m, stream: JSON.parse(m.stream) }))
        momList.sort((a, b) => {
          return b.created - a.created;
        });
        setMoments(momList);
      })();
    }, []);

    // auth
    const authContext = useContext(AuthContext);

    const doLogout = async () => {
      await authServiceLogout();
      authContext.logout();
    };
  // ~auth

  return (
    <View style={{ flex: 1, alignContent: 'center' }}>
      <MfeSpacer size={20} />
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <MfeButton onPress={() => navigation.navigate('Upload')}>Uploader</MfeButton>
        <MfeSpacer />
        {role === UserRole.Curator && (
          <>
            <MfeButton onPress={() => navigation.navigate('Laboratory')}>Laboratory</MfeButton>
            <MfeSpacer />
          </>
        )}
        <MfeButton onPress={doLogout}>Logout</MfeButton>
      </View>
      <MfeSpacer />
      <View style={{ flex: 1, justifyContent: 'center' }}>
        {moments === null && (<ActivityIndicator color={theme.colors.primary} size="small" />)}
        {moments?.length > 0 && (<MomentListView moments={moments} />)}
      </View>
    </View>
  );
}

export default function App() {

  // font
  const [fontLoaded] = useFonts({
    InconsolataRegular: require('./assets/fonts/Inconsolata/static/Inconsolata/Inconsolata-Regular.ttf'),
    InconsolataMedium: require('./assets/fonts/Inconsolata/static/Inconsolata/Inconsolata-Medium.ttf'),
    InconsolataLight: require('./assets/fonts/Inconsolata/static/Inconsolata/Inconsolata-Light.ttf'),
    InconsolataExtraLight: require('./assets/fonts/Inconsolata/static/Inconsolata/Inconsolata-ExtraLight.ttf'),
  });
  // ~font

  // theme
  const mfeAccentPurple = '#9437b3';
  const mfeAccentGreen = '#059033';
  let mfeAccent = mfeAccentGreen;
  const darkButton = '#222222';
  const lightButton = '#dcdcde';
  const black = '#000000';
  const white = '#ffffff';

  const fontConfig = {
    default: {
      regular: {
        fontFamily: 'InconsolataRegular',
        fontWeight: '400',
      },
      medium: {
        fontFamily: 'InconsolataMedium',
        fontWeight: '500',
      },
      light: {
        fontFamily: 'InconsolataLight',
        fontWeight: '300',
      },
      thin: {
        fontFamily: 'InconsolataExtraLight',
        fontWeight: '200',
      }
    }
  };
  
  fontConfig.ios = fontConfig.default;
  fontConfig.android = fontConfig.default;
  fontConfig.web = fontConfig.default;

  const lightTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: black,
      accent: mfeAccent,
      button: lightButton,
      inputBackground: white,
    },
    fonts: configureFonts(fontConfig),
    roundness: 0
  };

  const darkTheme = {
    ...DarkTheme,
    colors: {
      ...DarkTheme.colors,
      primary: white,
      accent: mfeAccent,
      button: darkButton,
      inputBackground: black,
    },
    fonts: configureFonts(fontConfig),
    roundness: 0
  };

  const colorScheme = useColorScheme();
  const [themeMode, setThemeMode] = useState(colorScheme);
  useEffect(() => {
    setThemeMode(colorScheme);
    changeNavigationBarColor('black');
  }, [colorScheme, fontLoaded]);
  const theme = useMemo(() => 
    themeMode === 'dark' ? darkTheme : lightTheme
  );
  // ~theme

  // auth
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            token: action.token,
            isLoading: false,
          };
        case 'LOGIN':
          return {
            ...prevState,
            isLogout: false,
            token: action.token,
          };
        case 'LOGOUT':
          return {
            ...prevState,
            isLogout: true,
            token: null,
          };
      }
    },
    {
      isLoading: true,
      isLogout: false,
      token: null
    }
  );

  React.useEffect(() => {
    const bootstrapAsync = async () => {
      let token = null;
      try {
        token = await authServiceToken();
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'RESTORE_TOKEN', token });
    };

    bootstrapAsync();
  }, []);

  const authContext = React.useMemo(
    () => ({
      login: async token => {
        dispatch({ type: 'LOGIN', token });
      },
      logout: () => {
        dispatch({ type: 'LOGOUT' });
      }
    }),
    []
  );
  // ~auth

  // archive context
  const [uploadId, setUploadId] = useState(null);
  const archiveContext = useMemo(
    () => ({ uploadId, setUploadId }), 
    [uploadId]
  );
  // ~archive context

  if (!fontLoaded) return <AppLoading />;

  return (
    <AuthContext.Provider value={authContext}>
    <ArchiveContext.Provider value={archiveContext}>
      <PaperProvider theme={theme}>
        <SafeAreaProvider>
            <NavigationContainer theme={theme}>
              <MfeShell>
                <StatusBar barStyle="light-content" backgroundColor="black" />
                <Stack.Navigator>
                  {state.token ? ([
                    <Stack.Screen key="archive" name="Archive" component={ArchiveScreen} options={{ headerShown: false, title: 'archive@mfe:~/' }}/>,
                    <Stack.Screen key="upload" name="Upload" component={UploadScreen} options={{ headerShown: false, title: 'archive@mfe:~/uploader' }}/>,
                    <Stack.Screen key="laboratory" name="Laboratory" component={LaboratoryScreen} options={{ headerShown: false, title: 'archive@mfe:~/laboratory' }}/>
                  ]) : (
                    <Stack.Screen name="Auth" component={AuthScreen} options={{ headerShown: false, title: 'auth@mfe:~/' }} />
                  )}
                </Stack.Navigator>
              </MfeShell>
            </NavigationContainer>
        </SafeAreaProvider>
      </PaperProvider>
    </ArchiveContext.Provider>
    </AuthContext.Provider>
  );
}
