import React, { useState, useEffect } from 'react';
import { Image, ScrollView, TouchableOpacity, View } from 'react-native';
import { Button, Modal, Portal, ProgressBar, Subheading, TextInput, Title, useTheme } from 'react-native-paper';

import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';

import Video from '../Video/Video';
import MfeSpacer from '../MfeSpacer';
import MfeButton from '../MfeButton';

import { MomentState } from '../../utils/constants';

import { getUploadEvent } from '../../services/uploadService.web';

const itemWidth = 300;
const itemHeight = 200;

export const ListMode = {
  Archive: 'list_mode.archive',
  Laboratory: 'list_mode.laboratory',
  Uploader: 'list_mode.uploader'
};

const MomentView = ({ moment, mode, close, onApproval, onRejection }) => {
  const theme = useTheme();
  const [details, setDetails] = React.useState('');
  return (
    <View style={{
      flex: 1,
      justifyContent: 'center',
      backgroundColor: theme.colors.surface,
      padding: '20%'
    }}>
      <Video moment={moment} />
      <MfeSpacer />
      <Title>{moment.mfe_key}</Title>
      <Subheading>
        {format(fromUnixTime(moment.created), 'dd/MM/yyyy')}
      </Subheading>
      {mode === ListMode.Laboratory && (
        <>
          <MfeSpacer />
          <TextInput
            placeholder="Add information about your decision here..."
            value={details}
            onChangeText={details => setDetails(details)}
            numberOfLines={2}
            multiline={true}
            mode="outlined"
            dense={true}
          />
          <MfeSpacer />
          <Button color={'green'} mode="contained" onPress={() => {
            onApproval(moment, details);
            close();
          }}>Approve</Button>
          <MfeSpacer />
          <Button color={'red'} mode="contained" onPress={() => {
            onRejection(moment, details);
            close();
          }}>Reject</Button>
        </>
      )}
      <MfeSpacer />
      <MfeButton mode="contained" onPress={close}>Close</MfeButton>
    </View>
  );
};

const MomentUploadItem = (props) => {
  const [moment, setMoment] = useState(props.moment);
  const [uploadEvent, setUploadEvent] = useState(null);
  const [progress, setProgress] = useState(0);

  const theme = useTheme();

  const onUploadEventProgress = (async evt => {
    setProgress(evt.detail.progress);
  }).bind(this);

  const onUploadEventCompleted = (async evt => {
    setMoment(evt.detail.moment);
    setUploadEvent(null);
  }).bind(this);

  const onUploadEventFailed = (async evt => {
    console.log(evt.detail.error);
  }).bind(this);

  // get existing upload event, listen for progress and completed
  useEffect(() => {
    const existingUploadEvent = getUploadEvent(moment.mfe_key);
    if (existingUploadEvent) {
      setUploadEvent(existingUploadEvent);
      existingUploadEvent.addEventListener('progress', onUploadEventProgress);
      existingUploadEvent.addEventListener('completed', onUploadEventCompleted);
      existingUploadEvent.addEventListener('failed', onUploadEventFailed);
    }
    return () => {
      if (existingUploadEvent) {
        existingUploadEvent.removeEventListener('progress', onUploadEventProgress);
        existingUploadEvent.removeEventListener('completed', onUploadEventCompleted);
        existingUploadEvent.removeEventListener('failed', onUploadEventFailed);
      }
    }
  }, []);

  return (
    <TouchableOpacity onPress={() => {
      if (moment?.stream?.hls) {
        console.log(moment);
        props.onPress();
      }
    }} style={{
      backgroundColor: 'transparent',
      width: itemWidth,
      height: itemHeight,
      aspectRatio: 1,
      margin: 20
    }}>
      {(!uploadEvent && moment.state === MomentState.Uploading) && (
        <View style={{ justifyContent: 'center', padding: 15, flex: 1, width: 300, minHeight: 150, maxHeight: 150, height: 150, backgroundColor: 'black' }}>
          <MfeButton onPress={() => props.onPress(moment)}>Resume</MfeButton>
        </View>
      )}
      {(uploadEvent && moment.state === MomentState.Uploading) && (
        <View style={{ justifyContent: 'center', padding: 15, flex: 1, width: 300, minHeight: 150, maxHeight: 150, height: 150, backgroundColor: 'black' }}>
          <ProgressBar progress={progress} color={theme.colors.accent} />
        </View>
      )}
      {moment.state !== MomentState.Uploading && (
        <Image source={{ uri: moment?.stream?.img?.thumbs?.[0] || null }} style={{
          backgroundColor: 'black',
          width: 300,
          height: 150,
          resizeMode: 'cover'
        }} />
      )}
      <Title>{moment.mfe_key}</Title>
      <Subheading>
        {format(fromUnixTime(moment.created), 'dd/MM/yyyy')}
      </Subheading>
    </TouchableOpacity>
  );
}

const MomentListItem = ({ moment, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress} style={{
      backgroundColor: 'transparent',
      width: itemWidth,
      height: itemHeight,
      aspectRatio: 1,
      margin: 20
    }}>
      <Image source={{ uri: moment?.stream?.img?.thumbs?.[0] || null }} style={{
        backgroundColor: 'black',
        width: 300,
        height: 150,
        resizeMode: 'cover'
      }} />
      <Title>{moment.mfe_key}</Title>
      <Subheading>
        {format(fromUnixTime(moment.created), 'dd/MM/yyyy')}
      </Subheading>
    </TouchableOpacity>
  );
};

function MomentListView({ moments, mode, onApproval, onRejection, onResume }) {
  const theme = useTheme();

  const [activeMoment, setActiveMoment] = React.useState(null);
  const [listMode, _] = React.useState(mode || ListMode.Archive);

  const Item = mode === ListMode.Uploader ? MomentUploadItem : MomentListItem;

  const RenderListItem = ({ item, open }) => {
    return (
      <Item
        moment={item}
        onPress={m => {
          if (m && m.state === MomentState.Uploading) {
            onResume(m);
          } else {
            open();
          }
        }}
      />
    );
  };

  const modalStyle = {
    flex: 1,
    backgroundColor: theme.colors.surface,
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%'
  };

  return (
    <ScrollView style={{ flex: 1 }}>
      <Portal>
        <Modal
          visible={!!activeMoment}
          onDismiss={() => setActiveMoment(null)}
          contentContainerStyle={modalStyle}
        >
          {!!activeMoment && (
            <MomentView
              moment={activeMoment}
              mode={listMode}
              close={() => setActiveMoment(null)}
              onApproval={onApproval}
              onRejection={onRejection}
            />
          )}
        </Modal>
      </Portal>
      <View style={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        {moments.map(m => (
          <RenderListItem key={m.mfe_key} item={m} open={() => setActiveMoment(m)} />
        ))}
      </View>
    </ScrollView>
  );
}
export default MomentListView;
